<template>
  <div class="mx-auto text-base">
    <!-- stats with Li-ne graph section -->
    <section id="chill_factor">
      <div
        class="w-full space-y-4 py-4 md:items-center lg:flex lg:space-x-4 lg:space-y-0"
      >
        <BaseCard
          class="relative h-72 w-full border bg-opacity-70 lg:w-1/4"
          id="chill-factor-ranking-card"
          :class="[
            setBgScoreBaseColor(myChillFactorData?.score),
            `${myChillFactorData?.tier?.toLowerCase()}-border`,
          ]"
        >
          <div
            class="z-50 h-full pb-2 pt-2 font-normal text-white sm:px-4 lg:px-0"
          >
            <div
              class="font-semibold"
              :class="checkpointStatsHistorical?.last_updated ? '' : 'h-full'"
            >
              <div class="flex items-center justify-between">
                <div class="flex flex-col text-xl">
                  <div class="flex items-center space-x-2 text-xl">
                    <div>Chill Factor</div>
                    <Tooltip text="" class="ml-1" v-if="false">
                      <InformationCircleIcon
                        class="h-4 w-4 text-infoIconColor"
                      />
                    </Tooltip>
                    <ArrowRightIcon class="h-3.5 w-3.5" v-if="false" />
                  </div>
                </div>
                <div
                  v-if="myChillFactorData?.score"
                  class="flex-shrink-0 hover:bg-white/10"
                  :class="[
                    imageLoading ? 'pointer-events-none p-2' : 'cursor-pointer',
                    'exclude rounded border border-[#FFFFFF3D]',
                    ,
                    `${myChillFactorData?.tier?.toLowerCase()}-border`,
                  ]"
                  @click.stop="shareOnTwitter"
                >
                  <IconLoading
                    v-if="imageLoading"
                    class="h-4 w-4 text-gray-100"
                  />
                  <!-- <ShareIcon v-else class="h-4 w-4 text-gray-100" />  -->
                  <Button class="px-1 text-whiteSmokeAlpha-88 text-xs" v-else
                    >X (Twitter)</Button
                  >
                </div>
              </div>
              <div
                v-if="checkpointStatsHistorical?.last_updated"
                class="font-light text-infoIconColor text-xs"
                style="font-family: 'Source Code Pro', monospace"
              >
                UPDATED:
                <span>
                  {{
                    new Date(
                      checkpointStatsHistorical?.last_updated,
                    ).toLocaleDateString()
                  }}</span
                >
              </div>
              <div
                :class="[
                  !imageLoading ? 'hidden' : 'flex',
                  'included items-center space-x-3 divide-x pt-2',
                ]"
              >
                <div>
                  <img
                    class="h-2.5"
                    src="../../images/frosty_logo.svg"
                    alt="Frosty Metrics Logo"
                  />
                </div>
                <div class="px-2">
                  <img
                    class="h-2.5"
                    src="../../images/the_tie_logo.svg"
                    alt="The Tie Logo"
                  />
                </div>
              </div>
              <div class="flex h-full w-full space-x-1">
                <div
                  v-if="checkpointStatsHistorical?.last_updated"
                  class="flex h-full w-full items-center justify-between space-x-3 lg:justify-center lg:space-x-0"
                >
                  <div class="block w-1/2 md:py-2 lg:hidden 2xl:block">
                    <ProgressBar
                      :currentSnapshot="
                        checkpointStatsHistorical.current_snapshot
                      "
                      class="w-28 sm:w-full"
                    />
                  </div>
                  <div class="relative" id="pieGraphContainer">
                    <div>
                      <highcharts :options="chartOptions"></highcharts>
                    </div>
                    <div
                      v-if="
                        ['chilly', 'frozen', 'subzero'].includes(
                          myChillFactorData?.tier?.toLowerCase(),
                        )
                      "
                      class="absolute right-[34%] top-2 z-20"
                    >
                      <img
                        src="../../images/DashboardPieChartSnow.svg"
                        class="flex-shrink-0"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="loading"
                  class="flex h-full w-full items-center justify-center pb-8"
                >
                  <IconLoading class="h-10 w-10" />
                </div>
                <div v-else class="h-full w-full">
                  <div
                    class="flex h-full w-full flex-col items-center justify-center space-y-6 pb-8"
                  >
                    <span class="text-center text-[#AEF7FF] text-base"
                      >Connect wallet address<br />to see score</span
                    >
                    <button
                      @click="showConnectModal = true"
                      class="rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] py-2 px-4 font-medium text-black text-sm tracking-wider"
                    >
                      CONNECT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="absolute mt-8 h-full">
            <div
              v-if="
                myChillFactorData?.tier?.toLowerCase() == 'warm' && !loading
              "
            >
              <RainAnimation />
            </div>
            <div
              v-else-if="
                myChillFactorData?.tier?.toLowerCase() != 'warm' && !loading
              "
            >
              <SnowAnimation />
            </div>
          </div>
        </BaseCard>
        <div class="grid grid-cols-1 gap-4 lg:w-3/4 lg:grid-cols-3">
          <div
            class="w-full"
            v-for="item in topStats"
            :key="item.id"
            id="lineGraphContainer"
          >
            <StatsWidget
              :stat-item="item"
              :show-loading="statsWidgetLoading"
              class="items-center justify-center lg:h-[8.5rem]"
              height=""
              :use-utc="
                item.title == 'TVL'
                  ? localDateEqualToUtc
                    ? true
                    : false
                  : true
              "
              :tooltip-text="item.tooltipText || item.title"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- table section -->
    <section
      class="grid grid-cols-1 space-y-6 md:grid-cols-2 md:gap-4 md:space-y-0 lg:grid-cols-6 lg:gap-0 lg:space-x-4 xl:pt-0"
    >
      <BaseCard class="col-span-1 lg:col-span-3 xl:col-span-4">
        <template #header>Network Coins</template>
        <template #controls>
          <BaseRadio
            v-model="selectedEcoSystemFilter"
            multiple
            button-width-class="w-32"
            :options="filterList"
            placeholder="Select Type"
          />
        </template>
        <div
          class="flex h-[28rem] flex-shrink-0 flex-grow flex-col rounded lg:h-[35rem]"
        >
          <div
            class="wrap flex-1 overflow-hidden overflow-x-auto overflow-y-auto rounded-lg shadow text-sm"
          >
            <div class="w-full">
              <Table
                :config="activeWalletsTableConfig"
                :data="_.orderBy(ecoSystemCoinsData, 'market_cap', 'desc')"
                class="h-72 md:h-36"
              ></Table>
            </div>
          </div>
        </div>
      </BaseCard>

      <!-- NewsSection -->
      <BaseCard class="lg:col-span-3 xl:col-span-2">
        <template #header>Network News</template>
        <div
          class="flex flex-shrink flex-grow flex-col overflow-x-auto rounded-lg text-textColor lg:h-[35rem]"
        >
          <div class="wrap h-96 space-y-2 overflow-y-auto pt-2 lg:h-full">
            <div v-if="newsLoading">
              <div v-for="i in 5" :key="i">
                <NewsTemplate />
              </div>
            </div>
            <div v-if="newsItems.length > 0">
              <div
                v-for="item in newsItems"
                :key="item.id"
                class="flex h-[7rem] items-center border-b border-gray-700"
              >
                <NewsWidget :news-item="item" sourceNameClass="w-1/2" />
              </div>
            </div>
            <div
              v-else-if="!newsLoading && newsItems"
              class="mt-auto flex h-full w-full justify-center"
            >
              <NoDataFound />
            </div>
          </div>
        </div>
      </BaseCard>
    </section>

    <!-- Mini Tables -->
    <section class="grid grid-cols-1 gap-4 py-4 md:grid-cols-2 lg:grid-cols-3">
      <!-- Top Projects -->
      <BaseCard
        class="flex w-full flex-grow flex-col rounded text-textColor"
        id="top_pro bas"
      >
        <template #header>Top Games</template>
        <template #controls>
          <BaseRadio
            v-model="selectedGameFiFilter"
            multiple
            button-width-class="w-32"
            :options="filterList"
            placeholder="Select Type"
          />
        </template>
        <div class="h-96 lg:h-[23rem]">
          <div
            class="wrap h-full flex-1 overflow-hidden overflow-x-auto overflow-y-auto shadow text-sm"
          >
            <div
              class="h-72 w-full font-normal md:h-36 lg:h-full"
              style="color: #a3a3a3"
            >
              <span
                class="mt-auto flex h-full w-full items-center justify-center"
                v-if="topProjectLoader"
              >
                <IconLoading class="h-10 w-10" />
              </span>
              <span v-else>
                <Table
                  bodyHeight="130"
                  :config="topProjectsTableConfig"
                  :data="sortData(topGamesData, 'number_of_transactions')"
                ></Table>
              </span>
            </div>
          </div>
        </div>
      </BaseCard>

      <!-- Top NFTs -->
      <BaseCard
        class="flex w-full flex-grow flex-col rounded text-textColor"
        label-length="20"
      >
        <template #header>Top NFT Collections</template>
        <template #controls>
          <BaseRadio
            v-model="selectedNftFilter"
            multiple
            button-width-class="w-32"
            :options="filterList"
            placeholder="Select Type"
            label-length="20"
          />
        </template>
        <div class="h-96 lg:h-[23rem]">
          <div
            class="wrap h-full flex-1 overflow-hidden overflow-x-auto overflow-y-auto shadow text-sm"
          >
            <div
              class="h-72 w-full font-normal md:h-36 lg:h-full"
              style="color: #a3a3a3"
            >
              <span
                class="mt-auto flex h-full w-full items-center justify-center"
                v-if="topNftLoader"
              >
                <IconLoading class="h-10 w-10" />
              </span>
              <span v-else>
                <Table
                  :config="topNFTsTableConfig"
                  :data="_.orderBy(topCollectionsData, 'floor_price', 'desc')"
                ></Table>
              </span>
            </div>
          </div>
        </div>
      </BaseCard>

      <!-- Top DeFi Projects -->
      <BaseCard
        class="flex w-full flex-grow flex-col rounded text-textColor"
        label-length="20"
      >
        <template #header>Top DeFi Projects</template>
        <template #controls>
          <BaseRadio
            v-model="selectedDeFiFilter"
            multiple
            button-width-class="w-32"
            :options="filterList"
            placeholder="Select Type"
            label-length="20"
          />
        </template>
        <div class="h-96 lg:h-[23rem]">
          <div
            class="wrap h-full flex-1 overflow-hidden overflow-x-auto overflow-y-auto shadow text-sm"
          >
            <div
              class="h-72 w-full font-normal md:h-36 lg:h-full"
              style="color: #a3a3a3"
            >
              <Table
                :config="topDeFiTableConfig"
                :data="sortData(topDeFiListData, 'tvl')"
              ></Table>
            </div>
          </div>
        </div>
      </BaseCard>
    </section>

    <!-- graph section  -->
    <section>
      <Graphs :tvl-chart-data="tvlChartData" />
    </section>
  </div>
  <ShareScoreModal
    v-if="openShareModal"
    :is-open="openShareModal"
    :data-url="imageDataUrl"
    :max-width="false"
    :tier="myChillFactorData?.tier"
    :score="myChillFactorData?.score"
    @close="closeShareModal"
  />
  <ConnectWalletModal
    :show-modal="showConnectModal"
    v-if="showConnectModal"
    @closeModal="showConnectModal = false"
    @entered-Address="(x) => enteredAddress(x)"
    :tier="myChillFactorData?.tier"
  />
  <ShowLevelModal
    :show-modal="showLevelModal && checkpointStatsHistorical?.current_snapshot"
    :chill-factor="checkpointStatsHistorical?.current_snapshot"
    v-if="showLevelModal"
    @closeModal="closeLevelModal()"
  />

  <!-- Hidden for Sharing -->
  <div
    style="width: 700px; height: 350px"
    class="absolute top-0 left-0 z-[-9999] space-y-4 lg:flex lg:space-y-0 lg:space-x-4"
  >
    <div
      style="width: 700px; height: 350px"
      id="chill-factor-score-card-2"
      class="relative rounded-2xl"
      :class="
        checkpointStatsHistorical?.current_snapshot?.score ? '!bg-primary' : ''
      "
    >
      <BaseCard
        style="width: 700px; height: 350px"
        :class="[
          checkpointStatsHistorical?.current_snapshot?.score
            ? `${setBgScoreBaseColor(
                checkpointStatsHistorical?.current_snapshot?.score,
              )}`
            : 'default_mcf_bg',
          `${checkpointStatsHistorical?.current_snapshot?.tier?.toLowerCase()}-border`,
        ]"
      >
        <div class="h-full w-full">
          <div
            v-if="selectedConfig.id != 'warm'"
            class="exclude absolute -left-2.5 -right-2.5 z-20 block"
            :class="[
              snowClass?.[selectedConfig.id],
              ['chilly', 'lukewarm'].includes(selectedConfig?.id)
                ? '-top-2.5'
                : '-top-5',
            ]"
          >
            <img :src="selectedConfig.imageUrl" class="w-full flex-shrink-0" />
          </div>
          <div
            class="flex w-full flex-col items-center justify-center space-y-4 pt-8"
          >
            <div class="font-black text-lg tracking-widest">
              {{ selectedConfig?.header }}
            </div>
            <div>
              <img :src="selectedConfig.textUrl" class="flex-shrink-0" />
            </div>
            <div
              class="text[#f3f2f6b8] font-medium leading-4 text-xs tracking-wide"
            >
              Chill Factor Range
            </div>
          </div>
          <div class="flex items-center justify-center pt-2">
            <button
              class="flex h-7 cursor-default items-center space-x-1 rounded px-4 text-sm"
              :style="selectedConfig?.rangeStyle"
              :class="selectedConfig?.rangeClass"
            >
              <CrownIcon />
              <span>{{ selectedConfig?.range }}</span>
            </button>
          </div>

          <div
            :class="`${setTier(
              checkpointStatsHistorical?.current_snapshot?.score,
            )?.toLowerCase()}-text-gradient py-6 text-center font-black text-4xl`"
          >
            {{
              simpleNumberFormatter(
                checkpointStatsHistorical?.current_snapshot?.score,
              )
            }}
          </div>
          <div
            :class="[
              !imageLoading ? 'flex' : 'flex',
              'included items-center justify-center space-x-3 divide-x',
            ]"
          >
            <div>
              <img
                class="h-2.5"
                src="../../images/frosty_logo.svg"
                alt="Frosty Metrics Logo"
              />
            </div>
            <div class="px-2">
              <img
                class="h-3"
                src="../../images/the_tie_logo.svg"
                alt="The Tie Logo"
              />
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script setup>
import GraphCard from '../../Shared/GraphCard.vue';
import StatsWidget from '../../Widgets/Stats.vue';
import PieGraphWidget from '../../Widgets/PieChart.vue';
import BaseCard from '../../Shared/BaseCard.vue';
import NewsWidget from '../../Widgets/News.vue';
import NewsTemplate from '../../load_templates/NewsTemplateCard.vue';
import NoDataFound from '../../load_templates/NoDataFound.vue';
import IconLoading from '../../icons/IconLoading.vue';
import _ from 'lodash';
import ConnectWalletModal from '../../Shared/ConnectWalletModal.vue';
import {
  InformationCircleIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@heroicons/vue/24/outline';
import Tooltip from '../../Shared/Tooltip.vue';
import { ref, onMounted, watch, reactive, computed, inject } from 'vue';
import Table from '../../Shared/Table.vue';
import Percent from '../../Shared/Percent.vue';
import { DashboardApi } from '../Dashboard/DashboardApi';
import ShareIcon from '../../icons/ShareIcon.vue';
import {
  rounded,
  percentage,
  formatBigPricing,
  calculateChange,
  decimals,
  localDateEqualToUtc,
  findMyStats,
  readableDate,
  pieChartBgColor,
  setBgScoreBaseColor,
  simpleNumberFormatter,
  setScoreBaseTextColor,
  setMinMaxTierCutOff,
  setTier,
  snowClass,
  chillFactorConfig,
  sortData,
  titleize,
} from '../../composeables/filters.js';
import ProgressBar from '../../Widgets/ProgressBar.vue';
import ShareScoreModal from '@/Shared/ShareScoreModal.vue';
import { makePng, imageDataUrl } from '~/composeables/shareChillFactor';

const api = new DashboardApi();
import { NftApi } from '../Nfts/NftApi';
const apiNft = new NftApi();

import { SubnetApi } from '../Subnets/SubnetApi';
const apiSubnet = new SubnetApi();
import Graphs from './Graphs.vue';
import BaseRadio from '../../Shared/BaseRadio.vue';
import SnowAnimation from '../../Shared/SnowAnimation.vue';
import RainAnimation from '../../Shared/RainAnimation.vue';
import { ChillFactorApi } from '../ChillFactor/ChillFactorApi';
const cfApi = new ChillFactorApi();

const statsWidgetLoading = ref(true);
const keyMetricsWidgetLoading = ref(true);
const ecosystemData = ref({});
const tvlChartData = ref({});
const nftData = ref([]);
const newsItems = ref([]);
const universeDefiTokens = inject('universeDefiTokens');
const universeTokens = inject('universeTokens');

onMounted(() => {
  Promise.all([
    fetchSubnetTransactions(),
    fetchSubnetActiveSenders(),
    fetchEcosystemActivityData(),
    fetchGamingData(),
    fetchTopCollections(),
    fetchTvlChartData(),
    fetchNews(),
    fetchEcosystemHighlightsData(),
    fetchAddress(),
  ]);
});

const topProjectsData = ref([]);

async function fetchGamingData() {
  topProjectsData.value = await api.fetchGamingData();
  topProjectLoader.value = false;
}

const newsLoading = ref('true');

async function fetchNews() {
  newsItems.value = await api.fetchNews();
  newsLoading.value = false;
}

const uniqueWallets = ref({});

async function fetchEcosystemActivityData() {
  let ecosystemActivityData = await api.fetchEcosystemActivityData();
  ecosystemData.value = ecosystemActivityData.data?.filter(
    (x) => x?.network == 'all',
  );
  uniqueWallets.value = ecosystemActivityData.unique_wallets;
}

async function fetchTvlChartData() {
  tvlChartData.value = await api.fetchTvlChartData();
  statsWidgetLoading.value = false;
}

// Adding Subnet Transaction in Transaction Mini Chart
const subnetTransactions = [];
const subnetActiveSenders = [];
async function fetchSubnetTransactions() {
  subnetTransactions.value = await apiSubnet.fetchTransactions(
    { id: 'mainnet', title: 'All L1s' },
    '90D',
  );
}
async function fetchSubnetActiveSenders() {
  subnetActiveSenders.value = await apiSubnet.fetchActiveSenders(
    { id: 'mainnet', title: 'All L1s' },
    '90D',
  );
}

//Top Mini Charts
const statsItems = computed(() => {
  let stats = [
    {
      id: 1,
      title: 'Network Transactions',
      total: 0,
      growth: 0,
      tooltipText:
        'This counts all transactions across the C-Chain and L1 that are running on mainnet',
    },
    {
      id: 2,
      title: 'Network Wallets',
      total: 0,
      from: 0,
      growth: 0,
      tooltipText:
        'This counts all active sender addresses across the C-Chain and L1 that are running on mainnet',
    },
    {
      id: 3,
      title: 'Network TVL',
      total: 0,
      from: 0,
      growth: 0,
      tooltipText: 'TVL data provided by DeFiLlama',
      usd: 'true',
    },
  ];
  let res = _.orderBy(ecosystemData.value, 'date', 'desc');
  let resTvl = _.orderBy(tvlChartData.value, 'date', 'desc');
  let subnetTxns = [];
  let subnetWallets = [];
  if (subnetTransactions.value?.length > 0) {
    subnetTxns = subnetTransactions.value.reverse();
  }
  if (subnetActiveSenders.value?.length > 0) {
    subnetWallets = subnetActiveSenders.value.reverse();
  }

  ['num_transactions', 'unique_addresses', 'tvl'].forEach((x, i) => {
    if (Object.keys(res).length > 0) {
      if (x == 'num_transactions') {
        const sumOfLast30Days = res
          .slice(0, 30)
          .reduce((partialSum, a) => partialSum + a[x], 0);

        const sumOfLast30To60Days = res
          .slice(30, 60)
          .reduce((partialSum, a) => partialSum + a[x], 0);

        stats[i].total =
          sumOfLast30Days +
          (subnetTxns
            ?.slice(0, 30)
            ?.reduce((partialSum, a) => partialSum + a['transactions'], 0) ||
            0);
        stats[i].from =
          sumOfLast30To60Days +
          (subnetTxns
            ?.slice(30, 60)
            ?.reduce((partialSum, a) => partialSum + a['transactions'], 0) ||
            0);
      } else {
        const sumOfLast30DaysSubnetActiveSenders = (
          subnetWallets?.slice(0, 30) || []
        ).reduce((partialSum, a) => partialSum + a['active_senders'], 0);

        const sumOfLast30To60DaysSubnetActiveSenders = (
          subnetWallets?.slice(30, 60) || []
        ).reduce((partialSum, a) => partialSum + a['active_senders'], 0);

        stats[i].total =
          uniqueWallets.value.total + sumOfLast30DaysSubnetActiveSenders;
        stats[i].from =
          uniqueWallets.value.from + sumOfLast30To60DaysSubnetActiveSenders;
      }
      stats[i].growth = calculateChange(stats[i].total, stats[i].from);
      stats[i].chart = res?.slice(0, 30).map((c) => {
        const transactions =
          subnetTransactions.value?.find((item) => item.date === c.date)
            ?.transactions || 0;
        const wallets =
          subnetWallets?.find((item) => item.date === c.date)?.active_senders ||
          0;
        let chartValue = 0;
        if (x === 'num_transactions') {
          chartValue = parseFloat(c[x]) + transactions;
        } else if (x === 'unique_addresses') {
          chartValue = parseFloat(c[x]) + wallets;
        }
        return [new Date(c.date).getTime(), chartValue];
      });
    }
    if (Object.keys(resTvl).length > 0 && x == 'tvl') {
      let last_30_day = resTvl.slice(0, 30);
      stats[i].total = last_30_day[0]['totalLiquidityUSD'];
      stats[i].from = last_30_day[last_30_day.length - 1]['totalLiquidityUSD'];
      stats[i].growth = calculateChange(stats[i].total, stats[i].from);
      stats[i].chart = resTvl?.slice(0, 30).map((c) => {
        return [
          parseInt(c.date) * 1000,
          parseFloat(c['totalLiquidityUSD']) || 0,
        ];
      });
    }
  });
  return stats;
});

// Ecosystem
const selectedEcoSystemFilter = ref('24H');
const activeWalletsTableConfig = computed(() => {
  return {
    cols: [
      {
        type: 'tokens',
        name: 'Projects',
        id: 'name',
        textLeft: 'left',
        openLink: true,
      },
      { type: 'dollar', name: 'Price ', id: 'price' },
      {
        type: 'percent',
        name: `${selectedEcoSystemFilter.value} Return`,
        id: 'price_return',
      },
      {
        type: 'dollar',
        name: 'Market Cap',
        id: 'market_cap',
        numbersLimit: 2,
        isBlank: true,
      },
      {
        type: 'dollar',
        name: 'Trading Volume',
        id: 'trading_volume',
        numbersLimit: 2,
      },
      { type: 'number', name: 'Transactions', id: 'transactions' },
      {
        type: 'percent',
        name: 'Transactions Change',
        id: 'transactions_change',
      },
      {
        type: 'dollar',
        name: 'TVL',
        id: 'tvl',
        numbersLimit: 2,
        isBlank: true,
      },
      {
        type: 'percent',
        name: 'TVL Change',
        id: 'tvl_change',
        numbersLimit: 2,
      },
    ],
    paddingLeftRight: 'px-3',
  };
});

const ecoSystemCoinsData = computed(() => {
  let tokens = universeTokens.value || [];
  let filterValue =
    selectedEcoSystemFilter.value == '24H'
      ? 'price_return_24_hours'
      : selectedEcoSystemFilter.value == '7D'
      ? 'price_return_7_days'
      : 'price_return_30_days';
  let tvl_suffix =
    selectedEcoSystemFilter.value == '24H'
      ? '1d'
      : selectedEcoSystemFilter.value;
  tokens = tokens.filter((x) => x.uid !== 'avalanche');
  return tokens.map((t) => {
    return {
      name: t.name,
      price: decimals(parseFloat(t.price), 4, 4),
      price_return: t[filterValue] || 0,
      transactions: t[`num_transactions_${tvl_suffix.toLowerCase()}`] || 0,
      transactions_change: t[`change_txn_${tvl_suffix.toLowerCase()}`] || 0,
      tvl_change: t[`change_${tvl_suffix.toLowerCase()}`] || 0,
      tvl: parseFloat(t?.tvl || 0),
      mcap: parseFloat(t.mcap) / parseFloat(t.tvl) || 0,
      market_cap: parseFloat(t.mcap ? t.mcap : t.market_cap) || 0,
      trading_volume: t?.trading_volume || 0,
      link: t.ticker,
      image: t.icon,
    };
  });
});

const topStats = computed(() => {
  return [...statsItems.value, ...ecoSystemKeyMetric.value];
});

const keyMetrics = ref([]);
const keyMetricsLoading = ref(true);

async function fetchEcosystemHighlightsData() {
  keyMetrics.value = await api.fetchEcosystemHighlightsData();
  keyMetricsLoading.value = false;
}

function parseChartData(chartData) {
  if (chartData) {
    return Object.entries(chartData).map(([key, value]) => [
      Date.parse(key),
      parseFloat(value),
    ]);
  } else {
    return null;
  }
}

//Key Metrics
const ecoSystemKeyMetric = computed(() => {
  keyMetricsWidgetLoading.value = false;
  const market_cap_chart = parseChartData(keyMetrics.value?.market_cap?.chart);
  const avax_market_cap_chart = parseChartData(
    keyMetrics.value?.avax_market_cap?.chart,
  );
  const dominance_chart = parseChartData(keyMetrics.value?.dominance?.chart);

  return [
    {
      title: 'Network Market Cap',
      bigNumber:
        '$' + formatBigPricing(keyMetrics.value?.market_cap?.number, 0) || 0,
      percent: keyMetrics.value?.market_cap?.change || 0,
      total: keyMetrics.value?.market_cap?.number,
      growth: keyMetrics.value?.market_cap?.change,
      chart: market_cap_chart,
      id: 1,
      usd: 'true',
    },
    {
      title: 'AVAX Market Cap',
      bigNumber:
        '$' + formatBigPricing(keyMetrics.value?.avax_market_cap?.number, 0) ||
        0,
      percent: keyMetrics.value?.avax_market_cap?.change || 0,
      total: keyMetrics.value?.avax_market_cap?.number,
      growth: keyMetrics.value?.avax_market_cap?.change,
      chart: avax_market_cap_chart,
      id: 2,
      tooltipText: 'Avalanche Market Cap',
      usd: 'true',
    },
    {
      title: 'AVAX Dominance',
      bigNumber:
        percentage(keyMetrics.value?.dominance?.number, 2, false, false) || 0,
      percent: keyMetrics.value?.dominance?.change || 0,
      total: keyMetrics.value?.dominance?.number * 100,
      growth: keyMetrics.value?.dominance?.change,
      chart: dominance_chart,
      suffix: '%',
      id: 3,
    },
  ];
});

// Filter
const filterList = ref(['24H', '7D', '30D']);

// Projects
const selectedGameFiFilter = ref('24H');
const topProjectLoader = ref(true);
const topProjectsTableConfig = computed(() => {
  return {
    cols: [
      {
        type: 'game_api',
        name: 'Game',
        id: 'game_api',
        textLeft: 'left',
      },
      {
        type: 'number',
        name: 'Total Transactions',
        id: 'number_of_transactions',
      },
      {
        type: 'percent',
        name: `${selectedGameFiFilter.value} Change`,
        id: 'transactions_growth_change',
      },
    ],
    paddingLeftRight: 'px-3',
  };
});

const topGamesData = computed(() => {
  let filterValue =
    selectedGameFiFilter.value == '24H'
      ? 'one'
      : selectedGameFiFilter.value == '7D'
      ? 'seven'
      : 'thirty';
  return topProjectsData.value.map((t) => {
    return {
      ...t,
      transactions_growth_change:
        t[`num_transactions_growth_${filterValue}_day`],
      number_of_transactions: t[`num_transactions_${filterValue}_day`],
    };
  });
});

// NFTs
const selectedNftFilter = ref('24H');
const topNftLoader = ref(true);
const topNFTsTableConfig = computed(() => {
  return {
    cols: [
      {
        type: 'nft',
        name: 'Collection',
        id: 'collection',
        textLeft: 'left',
        style: 'max-w-[15rem] text-left truncate w-full',
      },
      { type: 'decimal', name: 'Avg Sales (AVAX)', id: 'avg_sales_avax' },
      {
        type: 'number',
        name: 'Sales',
        id: 'sales_1d',
        noMultiply: true,
      },
    ],
    paddingLeftRight: 'px-3',
  };
});

const topCollections = ref([]);
watch(selectedNftFilter, async () => {
  await fetchTopCollections();
});

async function fetchTopCollections() {
  topNftLoader.value = true;
  let selectedTimeFrame =
    selectedNftFilter.value == '24H'
      ? '1day'
      : selectedNftFilter.value == '7D'
      ? '7day'
      : '30day';
  topCollections.value = [];
  topCollections.value = await apiNft.fetchTopCollections({
    order_by: selectedTimeFrame,
  });
  topNftLoader.value = false;
}

const topCollectionsData = computed(() => {
  let result = [];
  let filterValue =
    selectedNftFilter.value == '30D' ? '3d' : selectedNftFilter.value;
  if (topCollections.value?.length > 0) {
    topCollections.value.forEach((data) => {
      if (data.collection_name) {
        result = result.concat({
          collection: data.collection_name,
          link: data.collection,
          avg_sales_avax: data.avrg_price,
          sales_1d: data.num_trxs,
          image: data.image_url,
        });
      }
    });
  }
  return result;
});

//DeFi
const selectedDeFiFilter = ref('24H');
const topDeFiTableConfig = computed(() => {
  return {
    cols: [
      {
        type: 'defi',
        name: 'Project',
        id: 'name',
        textLeft: 'left',
        openLink: true,
      },
      { type: 'dollar', name: 'TVL', id: 'tvl', numbersLimit: 2 },
      {
        type: 'percent',
        name: `${selectedDeFiFilter.value} Change`,
        id: 'tvl_change',
      },
    ],
    paddingLeftRight: 'px-3',
  };
});

const topDeFiListData = computed(() => {
  let tokens = universeDefiTokens.value || [];
  let filterValue =
    selectedDeFiFilter.value == '24H' ? '1d' : selectedDeFiFilter.value;
  return tokens.map((t) => {
    let data = {
      name: t.name,
      tvl: parseFloat(t.tvl),
      tvl_change: t[`change_${filterValue.toLowerCase()}`],
      link: t.ticker,
      image: t.icon,
    };
    console.log('data', data);
    return data;
  });
});

// Pie Series
const universeChillFactor = inject('universeChillFactor');
const myAddress = ref(
  localStorage.getItem('my_address')
    ? JSON.parse(localStorage.getItem('my_address'))
    : '',
);
const myChillFactorData = ref('');

function getSubtitle() {
  let data = findMyStats(
    myAddress.value,
    universeChillFactor.value['leaderboard_v2']['unfiltered'],
  );
  myChillFactorData.value = data;
  if (data?.score && checkpointStatsHistorical.value?.components_historical) {
    let recent_data = checkpointStatsHistorical.value?.components_historical[0];
    return `<div class="space-y-1 px-2 w-full ${setScoreBaseTextColor(
      recent_data.score,
    )}">
      <div class="text-base w-full ${recent_data.tier?.toLowerCase()}-text-gradient"> ${simpleNumberFormatter(
      recent_data.score,
    )}</div>
      <div class="px-2 py-1 border border-[#1CFCE233] bg-transparent bg-opacity-30 w-full text-[8px] rounded">
       ${simpleNumberFormatter(calculateGrowth())} Since: ${readableDate(
      recent_data.cur_date,
    )}</div>
    <div><span class="text-[#638696] text-xs font-light">Points:</span> ${simpleNumberFormatter(
      calculatePoints(),
    )} </div>
    <div><span class="text-[#638696] text-xs font-light">Rank:</span> ${simpleNumberFormatter(
      localStorage.getItem('rankNumber') || data.rank,
    )}</div>
    </div>`;
  } else {
    return `<div class="text-[#638596]">
              <div class="font-bold p-2 text-lg"> -- </div>
              <div><span class="">Points:</span> <span class="text-gray-200"> --/-- </span> </div>
              <div><span class="">Rank:</span> <span class="text-gray-200"> --/-- </span> </div>
             </div>`;
  }
}

const checkpointStatsHistorical = ref([]);

const loading = ref(false);
async function fetchAddress() {
  loading.value = true;
  checkpointStatsHistorical.value = await cfApi.fetchStatsHistoricalData();
  loading.value = false;
  if (checkpointStatsHistorical.value?.current_snapshot?.tier_cutoffs) {
    localStorage.setItem(
      'tier_cutoff',
      JSON.stringify(
        setMinMaxTierCutOff(
          checkpointStatsHistorical.value?.current_snapshot?.tier_cutoffs,
        ),
      ),
    );
    calculateRange();
  }
}

function calculateGrowth() {
  if (checkpointStatsHistorical.value?.components_historical) {
    return (
      checkpointStatsHistorical.value?.components_historical[1]?.score -
      checkpointStatsHistorical.value?.components_historical[0]?.score
    ).toFixed(2);
  } else {
    return '';
  }
}

function calculatePoints() {
  if (checkpointStatsHistorical.value?.current_snapshot) {
    return Math.round(checkpointStatsHistorical.value?.current_snapshot?.score);
  } else {
    return '';
  }
}

const pieSeriesOption = computed(() => {
  let totalValue = 0; // Total value
  let myValue = 0; // Your value
  let remainingValue = 0; // Remaining value
  if (checkpointStatsHistorical.value?.current_snapshot) {
    totalValue =
      checkpointStatsHistorical.value.current_snapshot.tier_cutoffs[
        checkpointStatsHistorical.value.current_snapshot.tier.toLowerCase()
      ];

    myValue = parseFloat(
      checkpointStatsHistorical.value.current_snapshot.score,
    );
    remainingValue = totalValue - myValue;
    return [
      {
        name: 'Your Score',
        y: myValue,
        color: pieChartBgColor(myChillFactorData.value?.score),
      },
      {
        name: 'Remaining Score',
        y: remainingValue,
        color: '#1CFCE233',
      },
    ];
  } else {
    return [
      {
        name: 'Your Score',
        y: 0,
        color: '',
      },
      {
        name: 'Remaining Score',
        y: 0,
        color: '#1CFCE233',
      },
    ];
  }
});

// fetch Chill Factor status
const chartOptions = computed(() => {
  let width =
    window.innerWidth < 500
      ? 170
      : window.innerWidth < 1350 && window.innerWidth >= 1100
      ? 170
      : 220;
  return {
    chart: {
      width: width,
      height: 200,
      type: 'pie',
      backgroundColor: 'transparent',
      spacing: [0, 0, 0, 0], // Set all padding and margin to 0
    },
    credits: {
      enabled: false, // Disable watermark
    },
    title: {
      text: null, // Disable title
    },
    subtitle: {
      useHTML: true,
      text: `<div style="text-align: center; color: #30E0FF;">${getSubtitle()}</div>`,
      floating: true,
      verticalAlign: 'middle',
      style: {
        color: '#30E0FF', // Change subtitle color
      },
    },
    legend: {
      enabled: false, // Disable legends
    },
    tooltip: {
      enabled: false, // Disable tooltip
    },
    plotOptions: {
      pie: {
        size: 170,
        showInLegend: false, // Disable pointers
        opacity: 0.9,
        allowPointSelect: true,
        innerSize: '90%',
        cursor: 'pointer',
        dataLabels: {
          enabled: false, // Disable pointers
        },
        borderWidth: 0.1,
        shadow: false,
      },
    },
    series: [
      {
        events: {
          click: function (e) {
            e.preventDefault(); // prevent any action from occuring on "click" event
          },
        },
        data: pieSeriesOption.value,
      },
    ],
  };
});

// SHARE MODAL
import ShowLevelModal from '../../Shared/ShowLevelModal.vue';
const openShareModal = ref(false);
const closeShareModal = () => {
  document.body.classList.remove('overflow-y-hidden');
  openShareModal.value = false;
};

//CONNECT MODAL
const showConnectModal = ref(false);

function enteredAddress(e) {
  window.location.reload();
}

// Show Level Modal
const showLevelModal = ref(false);
onMounted(() => {
  if (myAddress.value?.length > 0) {
    const currentDate = new Date().toDateString();
    const showModalKey = `level_${myAddress.value}_${currentDate}`;
    if (localStorage.getItem('level_acheivement') != showModalKey) {
      showLevelModal.value = true;
      localStorage.setItem('level_acheivement', showModalKey);
    }
  }
});
function closeLevelModal() {
  showLevelModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}

// Direct share on Twitter
import { uploadedImageUrl } from '@/composeables/shareChillFactor';
import CrownIcon from '../../icons/CrownIcon.vue';

const generatingLink = ref(false);
const generatingLinkFor = ref(null);
const shareableLink = ref(null);
const imageBlob = ref(null);
const imageLoading = ref(null);

const shareOnTwitter = async () => {
  imageLoading.value = true;
  let linkFor = 'twitter';
  const elem = document.getElementById('chill-factor-score-card-2');
  let dimensions = { height: elem.clientHeight, width: elem.clientWidth };
  await makePng('chill-factor-score-card-2', null, false, dimensions);
  // document.body.classList.add('overflow-y-hidden');

  generatingLink.value = true;
  generatingLinkFor.value = linkFor;
  const file = await makeImageFile(uploadedImageUrl.value);
  await uploadChillFactor(file, linkFor);
};
const makeImageFile = async (dataUrl) => {
  let fetchUrl = await fetch(dataUrl);
  imageBlob.value = await fetchUrl.blob();
  return new File([imageBlob.value], 'chillFactor', { type: 'image/png' });
};
const uploadChillFactor = async (file, linkFor) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const data = await cfApi.uploadImage(formData);
    generatingLink.value = false;
    shareableLink.value = data.url;
    switch (linkFor) {
      case 'twitter':
        makeTwitterUrl();
        generatingLinkFor.value = null;
        break;
    }
  } catch (e) {
    console.error(e);
  }
};
const twitterDescription = computed(() => {
  return `I just checked my Avalanche Chill Factor score on FrostyMetrics and am ${checkpointStatsHistorical.value?.current_snapshot?.tier.toUpperCase()} with a score of ${
    checkpointStatsHistorical.value?.current_snapshot?.score
  }. Are you chiller than me?

To check your Chill Factor visit Frosty Metrics: https://frostymetrics.com/
`;
});
const makeTwitterUrl = () => {
  if (shareableLink.value.length) {
    const twitterPostIntentUrl = new URL('https://twitter.com/intent/tweet?');
    twitterPostIntentUrl.searchParams.append('url', shareableLink.value);
    twitterPostIntentUrl.searchParams.append('text', twitterDescription.value);
    imageLoading.value = false;
    window.open(
      twitterPostIntentUrl.href,
      'Share Chill Factor',
      'width=700,height=650',
    );
  }
};
function calculateRange() {
  if (selectedConfig.value) {
    if (selectedConfig.value.id == 'warm') {
      chillFactorConfig.find(
        (x) => x.id == 'warm',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs['warm'],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'lukewarm'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'lukewarm') {
      chillFactorConfig.find(
        (x) => x.id == 'lukewarm',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'lukewarm'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'chilly'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'chilly') {
      chillFactorConfig.find(
        (x) => x.id == 'chilly',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'chilly'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'frozen'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'frozen') {
      chillFactorConfig.find(
        (x) => x.id == 'frozen',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'frozen'
        ],
      )}-${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'subzero'
        ],
      )}`;
    } else if (selectedConfig.value.id == 'subzero') {
      chillFactorConfig.find(
        (x) => x.id == 'subzero',
      ).range = `${simpleNumberFormatter(
        checkpointStatsHistorical.value?.current_snapshot.tier_cutoffs[
          'subzero'
        ],
      )}`;
    }
  }
}

const selectedConfig = computed(() => {
  const tier = setTier(
    checkpointStatsHistorical.value?.current_snapshot?.score,
  );
  return (
    chillFactorConfig.find((item) => item.id === tier?.toLowerCase()) || {}
  );
});
</script>
<style scoped>
.dashboard_snowfall {
  background-image: url('../../images/snowfall-transparent-backgroun-unscreen.gif');
}
</style>
