<template>
  <div
    class="box-shadow text-base relative flex h-[18.5rem] flex-col overflow-hidden rounded-lg border border-grid p-1 font-normal text-textColor"
    style="background: rgba(82, 104, 184, 0.04)"
  >
    <div class="text-xs h-full p-2">
      <div class="flex h-full flex-col justify-between">
        <div>
          <div class="flex items-center space-x-2 py-1">
            <img
              v-if="data.data?.icon || data.data?.image || data.data?.game_api"
              :src="
                data.data?.icon ||
                data.data?.image ||
                getGameIcon(
                  convertUnderScoreToDash(data.data?.game_api),
                  projectImagesBaseLink,
                )
              "
              class="h-10 rounded-full"
            />
            <div
              v-else
              class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-600 text-white"
            ></div>
            <Tooltip :text="projectName" v-if="projectName.length > 12">
              <div class="text-base font-semibold text-white">
                {{
                  capitalizeWords(projectName, '_').slice(0, 12) +
                  (capitalizeWords(projectName, '_').length > 12 ? '...' : '')
                }}
              </div>
            </Tooltip>
            <div v-else class="text-base font-semibold text-white">
              {{
                capitalizeWords(projectName, '_').slice(0, 12) +
                (capitalizeWords(projectName, '_').length > 12 ? '...' : '')
              }}
            </div>
          </div>
          <div class="font-sourceSans py-2 font-sourceCodePro text-xxs">
            <div
              v-for="metric in metrics"
              :key="Object.keys(metric)[0]"
              class="flex justify-between border-b border-gray-700 border-opacity-60 py-2"
            >
              <span>{{ Object.keys(metric)[0] }}</span>
              <span>{{
                data && data.data ? formatEntity(metric, data) : '-'
              }}</span>
            </div>
          </div>
        </div>
        <div class="text-xs pt-1">
          <a
            :href="data?.link"
            class="flex cursor-pointer items-center text-blue-500"
            target="_blank"
            >Go To Page
            <ArrowUpRightIcon class="ml-2 h-4 w-4 font-semibold" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowUpRightIcon } from '@heroicons/vue/24/outline';
import {
  titleize,
  formatBigPricing,
  capitalizeWords,
  convertUnderScoreToDash,
  getGameIcon,
} from '../composeables/filters.js';
import Tooltip from '../Shared/Tooltip.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const props = defineProps({
  classes: { type: String, default: '' },
  data: { type: Object, default: {} },
  metrics: { type: Array, default: ['Name', 'Price', 'Stats', 'Description'] },
  component: { type: String, default: null },
});

const projectImagesBaseLink = store.getters.projectImagesBaseLink;
const formatKey = (key) => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
function formatEntity(metric, data) {
  const value = data?.data[Object.values(metric)[0]];
  if (value === undefined) return '-';

  const formattedValue =
    metric.decimals !== undefined
      ? parseFloat(value).toFixed(metric.decimals)
      : formatBigPricing(value);

  return metric.prefix ? `${metric.prefix}${formattedValue}` : formattedValue;
}

const projectName = computed(() => {
  return props.data?.data?.name || props.data?.mapping_id;
});
</script>
