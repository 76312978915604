import axios from 'axios';
export class RecommendationApi {
  async reqMethod(path, query) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }
  async fetchRecommendation() {
    let address = localStorage.getItem('my_address')
      ? JSON.parse(localStorage.getItem('my_address'))
      : '';
    let savedData = localStorage.getItem(
      `my_address_recommendation_${address}`,
    );
    if (savedData) {
      const today = new Date().toISOString().split('T')[0];
      const savedAt = new Date(JSON.parse(savedData).savedAt)
        .toISOString()
        .split('T')[0];
      if (today !== savedAt) {
        localStorage.removeItem(`my_address_recommendation_${address}`);
        savedData = null;
      }
    }
    if (address && !savedData) {
      const [res, error] = await this.reqMethod(
        `/recommendations/${address}/user_recommendations`,
      );
      if (!error) {
        localStorage.setItem(
          `my_address_recommendation_${address}`,
          JSON.stringify({ ...res, savedAt: new Date() }),
        );
      }
      return error ? error : res;
    } else {
      return savedData ? JSON.parse(savedData) : [];
    }
  }
  async fetchTopRecommendations() {
    let response = await this.fetchRecommendation();
    if (response && response.recommendations) {
      return response.recommendations.slice(0, 4);
    }
    return [];
  }
}
