<template>
  <nav
    id="header"
    class="stacked-radial relative h-20 w-full rounded-lg border border-tertiary sm:h-44 md:h-44 xl:h-32"
  >
    <div class="flex h-full w-full overflow-hidden">
      <template v-for="index in 2" :key="index">
        <span v-if="rankTierValue?.tier?.toLowerCase() == 'warm'">
          <RainAnimation class="snowAnimation h-full w-full" />
        </span>
        <span v-else-if="rankTierValue?.tier?.toLowerCase() != 'warm'">
          <SnowAnimation class="snowAnimation h-full w-full" />
        </span>
      </template>
    </div>
    <div class="absolute -left-4 -top-2.5 z-20">
      <img src="../images/header_snow_left.svg" class="flex-shrink-0" />
    </div>
    <div class="absolute -right-2 -top-1.5 z-20 hidden sm:block">
      <img src="../images/header_snow_right.svg" class="flex-shrink-0" />
    </div>

    <div class="px-2 md:px-6 lg:px-10">
      <div class="absolute inset-0 px-2 md:px-6 lg:px-10">
        <div class="flex w-full items-end justify-between">
          <div
            class="mt-4 flex h-12 w-1/2 items-end justify-between px-3 sm:mt-4 sm:px-0"
          >
            <Link href="/">
              <img
                src="../images/Frosty_Metrics.png"
                width="350"
                class="hidden sm:block"
              />
              <img
                src="../images/FrostyMetricsMobileLogo.svg"
                width="90"
                class="mt-4 block sm:hidden"
              />
            </Link>
          </div>

          <!-- CHILL FACTOR STATUS -->
          <div class="text-sm hidden space-x-2 px-2 pb-2 sm:flex">
            <AnimationToggle />
          </div>
          <div
            class="text-xs lg:text-base grid w-1/2 grid-cols-4 items-center sm:hidden"
          >
            <div class="col-span-3 flex justify-end">
              <button
                v-if="myAddress?.length == 0"
                @click="openModal()"
                class="h-8 rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] px-3 text-black sm:px-6 sm:py-2"
              >
                Connect Wallet
              </button>
              <button
                v-else
                class="h-8 rounded-3xl border border-[#FFFFFF3D] px-2 font-bold"
                :class="chillFactorColor || 'bg-gray-800'"
                :key="myAddressState?.length"
                @click="openModal()"
              >
                <span :class="chillFactorTextColor">{{
                  rankTierValue?.tier
                }}</span>
                <span
                  v-if="tiers.includes(rankTierValue?.tier?.toLowerCase())"
                  class="text-sm ml-2 font-light text-[#f3f2f6e0]"
                  >#{{ simpleNumberFormatter(rankNum) }}
                </span>
              </button>
            </div>

            <button
              id="nav-toggle"
              class="focus:shadow-outline col-span-1 flex w-full transform items-center justify-end p-1 transition duration-300 ease-in-out hover:scale-105 focus:outline-none"
              @click="openMenu()"
            >
              <Bars3Icon class="w-8" />
            </button>
          </div>
        </div>

        <div
          class="relative mx-auto mt-0 hidden w-full flex-wrap items-center justify-between py-2 sm:flex sm:space-x-8"
        >
          <div class="w-full flex-col items-center justify-between">
            <div
              class="text-sm sm:text-base z-20 w-full flex-grow text-white sm:mt-2 sm:p-0 sm:px-3 md:p-0 lg:mt-0 lg:flex lg:w-auto lg:items-center lg:p-0"
              id="nav-content"
            >
              <ul
                class="list-reset items-center justify-between space-y-2 py-3 sm:flex-1 sm:py-0 xl:flex xl:space-y-0"
              >
                <GlobalSearch class="mb-2 h-10 sm:hidden sm:h-full" />
                <span
                  class="text-sm space-y-1 sm:flex sm:space-x-3 sm:space-y-0 xl:space-x-5"
                >
                  <li
                    class="cursor-pointer py-1 md:py-2 xl:py-2"
                    v-for="link in links"
                    :key="link.url"
                  >
                    <Link
                      :class="`${
                        link.activeTab.includes(findCurrentUrl($page.url))
                          ? 'border-b-[1px] border-blue-400 py-2 font-normal'
                          : 'border-transparent text-blue-300'
                      }`"
                      :href="link.url"
                      >{{ link.name }}
                    </Link>
                  </li>
                </span>
                <li>
                  <div
                    class="h-10 items-center justify-between sm:flex md:flex md:space-x-3"
                  >
                    <GlobalSearch class="hidden sm:block sm:w-8/12" />
                    <div
                      class="hidden border-r-[1px] border-[#5268B8] md:h-full"
                    ></div>
                    <div
                      class="flex items-center justify-between space-x-3 pt-2 sm:pt-0"
                    >
                      <button
                        v-if="myAddress?.length == 0"
                        @click="openModal()"
                        class="hidden h-full rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] py-2 px-6 text-black sm:block lg:px-3"
                      >
                        Connect Wallet
                      </button>
                      <button
                        v-else
                        class="hidden h-full rounded-3xl border border-[#FFFFFF3D] py-2 px-3 font-bold sm:block"
                        :class="`${
                          chillFactorColor ? chillFactorColor : 'bg-gray-800'
                        } ${
                          rankTierValue?.tier?.toLowerCase() ==
                          'address not found'
                            ? 'text-xs'
                            : ''
                        }`"
                        @click="openModal()"
                      >
                        <span :class="chillFactorTextColor">{{
                          rankTierValue?.tier
                        }}</span>
                        <span
                          v-if="
                            tiers.includes(rankTierValue?.tier?.toLowerCase())
                          "
                          class="ml-2 font-light text-[#f3f2f6e0]"
                          >#{{ simpleNumberFormatter(rankNum) }}
                        </span>
                      </button>
                    </div>
                    <button
                      v-if="myAddress?.length == 0"
                      @click="openModal()"
                      class="mt-3 h-full rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] py-2 px-3 text-black sm:hidden sm:px-6"
                    >
                      Connect Wallet
                    </button>
                    <button
                      v-else
                      class="text-sm mt-3 h-full rounded-3xl border border-[#FFFFFF3D] px-2 font-bold sm:hidden"
                      :class="`${
                        chillFactorColor ? chillFactorColor : 'bg-gray-800'
                      } ${
                        rankTierValue?.tier?.toLowerCase() ==
                        'address not found'
                          ? 'text-xs'
                          : ''
                      }`"
                      :key="myAddressState?.length"
                      @click="openModal()"
                    >
                      <span :class="chillFactorTextColor">{{
                        rankTierValue?.tier
                      }}</span>
                      <span
                        v-if="
                          tiers.includes(rankTierValue?.tier?.toLowerCase())
                        "
                        class="text-sm ml-2 font-light text-[#f3f2f6e0]"
                        >#{{ simpleNumberFormatter(rankNum) }}
                      </span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConnectWalletModal
      :show-modal="showModal"
      v-if="showModal"
      @closeModal="closeModal()"
      @entered-Address="(x) => enteredAddress(x)"
      :tier="rankTierValue?.tier"
    />
    <NavbarMenuModal
      v-if="showMenu"
      :show-menu="showMenu"
      :my-address="myAddress"
      :chill-factor-text-color="chillFactorTextColor"
      :rank-tier-value="rankTierValue"
      :tiers="tiers"
      @closeModal="closeModal()"
    />
  </nav>
</template>

<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import GlobalSearch from '../Widgets/GlobalSearch.vue';
import ConnectWalletModal from './ConnectWalletModal.vue';
import NavbarMenuModal from './NavbarMenuModal.vue';
import { ref, inject, computed, watchEffect, onMounted } from 'vue';
const myAddress = ref(
  localStorage.getItem('my_address')
    ? JSON.parse(localStorage.getItem('my_address'))
    : '',
);
import {
  findMyStats,
  setScoreBaseColor,
  setTier,
  simpleNumberFormatter,
} from '../composeables/filters';
import { Bars3Icon } from '@heroicons/vue/20/solid';
import { ChillFactorApi } from '../Pages/ChillFactor/ChillFactorApi';
import SnowAnimation from '../Shared/SnowAnimation.vue';
import RainAnimation from '../Shared/RainAnimation.vue';
import AnimationToggle from '../Shared/AnimationToggle.vue';
const api = new ChillFactorApi();

const myAddressState = computed(async () => {
  let data = await fetchCurrentSnapshot();
  localStorage.setItem('my_status', JSON.stringify(data));
  return data || '';
});

const rankTierValue = ref({ tier: 'Loading...' });
async function fetchCurrentSnapshot() {
  rankTierValue.value = await api.fetchCurrentSnapshot();
  if (rankTierValue.value?.score) {
    let rank =
      JSON.parse(localStorage.getItem('excludeInsAndExcAddresses')) == false ||
      false
        ? rankTierValue.value?.rank
        : rankTierValue.value?.filtered_rank;
    rankNum.value = rank;
    return rankTierValue.value;
  } else {
    rankTierValue.value = { tier: 'Address Not Found' };
  }
}

const chillFactorColor = computed(() => {
  showRankTier(rankTierValue.value);
  return `${rankTierValue.value?.tier?.toLowerCase()}-button-bg`;
});

const chillFactorTextColor = computed(() => {
  return `${rankTierValue.value?.tier?.toLowerCase()}-text-gradient`;
});

const tiers = ['warm', 'lukewarm', 'chilly', 'frozen', 'subzero'];
function showRankTier(rankTierValue) {
  if (rankTierValue?.score && rankTierValue.rank) {
    let tier = setTier(rankTierValue?.score);
    tier = tier ? tier : rankTierValue?.tier;
  }
}

const mobileView = ref(false);
function check(e) {
  // mobileView.value = !mobileView.value;
  var navMenuDiv = document.getElementById('nav-content');
  var navMenu = document.getElementById('nav-toggle');
  var target = (e && e.target) || (event && event.srcElement);

  if (!checkParent(target, navMenuDiv)) {
    if (checkParent(target, navMenu)) {
      if (navMenuDiv.classList.contains('hidden')) {
        navMenuDiv.classList.remove('hidden');
      } else {
        navMenuDiv.classList.add('hidden');
      }
    } else {
      navMenuDiv.classList.add('hidden');
    }
  }
}

function checkParent(t, elm) {
  while (t.parentNode) {
    if (t == elm) {
      return true;
    }
    t = t.parentNode;
  }
  return false;
}
const links = ref([
  {
    name: 'Dashboard',
    url: '/dashboard',
    activeTab: ['dashboard'],
  },
  {
    name: 'Chill Factor',
    url: '/chill_factor',
    activeTab: ['chill_factor'],
  },
  {
    name: 'DeFi',
    url: '/defi',
    activeTab: ['defi'],
  },
  {
    name: 'Gaming',
    url: '/gaming',
    activeTab: ['gaming', 'projects', 'tokens'],
  },
  {
    name: 'NFT',
    url: '/nfts',
    activeTab: ['nfts'],
  },
  {
    name: 'Avalanche L1s',
    url: '/l1',
    activeTab: ['l1', 'subnets'],
  },
  {
    name: 'Recommendation For You',
    url: '/recommendation',
    activeTab: ['recommendation'],
  },
]);

function findCurrentUrl(url) {
  let currentUrl = url.split('/').filter((x) => x)[0];
  return currentUrl === undefined ? 'dashboard' : currentUrl;
}
const showMenu = ref(false);
function openMenu() {
  showMenu.value = true;
  document.body.classList.add('overflow-y-hidden');
}

const showModal = ref(false);
function openModal() {
  showModal.value = true;
  document.body.classList.add('overflow-y-hidden');
}

function closeModal() {
  showModal.value = false;
  showMenu.value = false;
  document.body.classList.remove('overflow-y-hidden');
}

function enteredAddress(e) {
  window.location.reload();
}

const rankNum = ref(localStorage.getItem('rankNumber'));

// In the Navbar
const sharedState = inject('sharedState');
watchEffect(() => {
  if (typeof sharedState.value == 'boolean') {
    rankNum.value =
      sharedState.value == true
        ? rankTierValue.value?.filtered_rank
        : sharedState.value == false
        ? rankTierValue.value?.rank
        : localStorage.getItem('rankNumber');
  } else {
    rankNum.value = localStorage.getItem('rankNumber');
  }
});
</script>
<style scoped>
.stacked-radial {
  background: rgb(15, 37, 94);
  background: linear-gradient(
    144deg,
    rgba(15, 37, 94, 1) 0%,
    rgba(11, 25, 55, 1) 26%,
    rgba(9, 33, 67, 1) 38%,
    rgba(8, 27, 51, 1) 47%,
    rgba(8, 39, 63, 1) 58%,
    rgba(9, 26, 49, 1) 68%,
    rgba(8, 27, 36, 1) 77%,
    rgba(7, 24, 27, 1) 84%,
    rgba(4, 19, 24, 1) 91%,
    rgba(7, 12, 21, 1) 100%
  );
}

.snowfall {
  background-image: url('../images/snowfall-transparent-backgroun-unscreen.gif');
  background-repeat: repeat-x;
  opacity: 0.7;
}
</style>
