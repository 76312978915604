<template>
  <div class="space-y-1 py-2">
    <div v-if="address">
      <div class="w-full py-4 xl:w-1/2">
        <div style="font-size: 1.5rem">Recommended for you</div>
        <div
          v-if="!loading"
          class="text-xs text-gray-400"
          style="font-size: 12px"
          v-html="recommendedProjectsText"
        ></div>
      </div>
      <div class="grid gap-4 md:grid-cols-2">
        <BaseCard class="flex h-[26rem] flex-shrink flex-col">
          <template #header>
            <div class="w-full space-y-1">
              <div class="text-base flex">
                DeFi projects for you
                <Tooltip
                  text="Base on your activity, recommended Defi"
                  class="ml-1"
                >
                  <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
                </Tooltip>
              </div>
              <div
                class="flex h-80 w-full items-center justify-center"
                v-if="loading"
              >
                <IconLoading class="h-6 w-6" />
              </div>
              <div
                class="grid w-full grid-cols-1 gap-x-3 gap-y-3 py-1 lg:grid-cols-2 xl:grid-cols-3"
                v-else-if="paginatedDefiData && !loading"
              >
                <template
                  v-for="(defiItem, index) in paginatedDefiData"
                  :key="index"
                >
                  <RecommendedBaseCard
                    :metrics="defiItem.is_l1 ? l1Metrics : defiMetrics"
                    :data="defiItem"
                    class="w-full"
                  />
                </template>
              </div>
            </div>
          </template>
          <div class="h-8 w-full pb-4" v-if="!loading && paginatedDefiData">
            <BasePaginate v-model="defiPage" :pages="defiTotalPages" />
          </div>
        </BaseCard>
        <!-- Gaming Section  -->
        <BaseCard class="flex h-[26rem] flex-shrink flex-col">
          <template #header>
            <div class="w-full space-y-1">
              <div class="text-base flex">
                Gaming projects for you
                <Tooltip
                  text="Base on your activity, recommended Gamefi"
                  class="ml-1"
                >
                  <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
                </Tooltip>
              </div>
              <div
                class="flex h-80 w-full items-center justify-center"
                v-if="loading"
              >
                <IconLoading class="h-6 w-6" />
              </div>
              <div
                class="grid w-full grid-cols-1 gap-x-3 gap-y-3 py-1 lg:grid-cols-2 xl:grid-cols-3"
                v-else-if="paginatedGamingData && !loading"
              >
                <template
                  v-for="(gameItem, index) in paginatedGamingData"
                  :key="index"
                >
                  <RecommendedBaseCard
                    :metrics="gameItem.is_l1 ? l1Metrics : gamingMetrics"
                    :data="gameItem"
                    class="w-full"
                    component="gaming"
                  />
                </template>
              </div>
            </div>
          </template>
          <div class="h-8 w-full" v-if="!loading && paginatedGamingData">
            <BasePaginate v-model="gamingPage" :pages="gamingTotalPages" />
          </div>
        </BaseCard>
      </div>

      <div class="grid gap-4 pt-4 md:grid-cols-2">
        <BaseCard class="flex h-[26rem] flex-shrink flex-col">
          <template #header>
            <div class="w-full space-y-1">
              <div class="text-base flex">
                NFT projects for you
                <Tooltip
                  text="Base on your activity, recommended NFT"
                  class="ml-1"
                >
                  <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
                </Tooltip>
              </div>
              <div
                class="flex h-80 w-full items-center justify-center"
                v-if="loading"
              >
                <IconLoading class="h-6 w-6" />
              </div>
              <div
                class="grid w-full grid-cols-1 gap-x-3 gap-y-3 py-1 lg:grid-cols-2 xl:grid-cols-3"
                v-else-if="paginatedNftData && !loading"
              >
                <template
                  v-for="(nftItem, index) in paginatedNftData"
                  :key="index"
                >
                  <RecommendedBaseCard
                    :metrics="nftItem.is_l1 ? l1Metrics : nftMetrics"
                    :data="nftItem"
                    class="w-full"
                  />
                </template>
              </div>
            </div>
          </template>
          <div class="h-8 w-full" v-if="!loading && paginatedNftData">
            <BasePaginate v-model="nftPage" :pages="nftTotalPages" />
          </div>
        </BaseCard>
        <BaseCard class="flex h-[26rem] flex-shrink flex-col">
          <template #header>
            <div class="w-full space-y-1">
              <div class="text-base flex">
                Token projects for you
                <Tooltip
                  text="Base on your activity, recommended Token"
                  class="ml-1"
                >
                  <InformationCircleIcon class="h-4 w-4 text-infoIconColor" />
                </Tooltip>
              </div>
              <div
                class="flex h-80 w-full items-center justify-center"
                v-if="loading"
              >
                <IconLoading class="h-6 w-6" />
              </div>
              <div
                class="grid w-full grid-cols-1 gap-x-3 gap-y-3 py-1 lg:grid-cols-2 xl:grid-cols-3"
                v-else-if="paginatedTokenData && !loading"
              >
                <template
                  v-for="(tokenItem, index) in paginatedTokenData"
                  :key="index"
                >
                  <RecommendedBaseCard
                    :metrics="tokenItem.is_l1 ? l1Metrics : tokenMetrics"
                    :data="tokenItem"
                    class="w-full"
                  />
                </template>
              </div>
            </div>
          </template>
          <div class="h-8 w-full" v-if="!loading && paginatedTokenData">
            <BasePaginate v-model="tokenPage" :pages="tokenTotalPages" />
          </div>
        </BaseCard>
      </div>

      <!-- MOST INTERACTED -->
      <div class="py-4" v-show="loading || mostUsedData?.length > 0">
        <div class="flex pb-2 text-white" style="font-size: 1.5rem">
          You interacted with most
        </div>
        <BaseCard class="h-[9.6rem] w-full font-sourceCodePro">
          <template #header>
            <!-- Added horizontal scroll using overflow-x-auto and flex -->
            <div v-if="loading" class="mt-10 flex w-full justify-center">
              <IconLoading class="h-8 w-8" />
            </div>
            <div v-else class="flex w-full space-x-3 overflow-auto pb-2">
              <div v-for="(myMostUsedItem, index) in mostUsedData" :key="index">
                <BaseCard class="md:w-60">
                  <div
                    class="flex items-center space-x-2 border-b border-gray-800 border-opacity-80 pb-2"
                  >
                    <img
                      v-if="
                        myMostUsedItem?.data[0]?.icon ||
                        myMostUsedItem?.data[0]?.image ||
                        myMostUsedItem?.data[0]?.game_api
                      "
                      :src="
                        myMostUsedItem?.data[0]?.icon ||
                        myMostUsedItem?.data[0]?.image ||
                        getGameIcon(
                          convertUnderScoreToDash(
                            myMostUsedItem?.data[0]?.game_api,
                          ),
                          projectImagesBaseLink,
                        )
                      "
                      alt="Logo"
                      class="h-10 w-10 rounded-full"
                    />
                    <div
                      v-else
                      class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-600 text-white"
                    ></div>
                    <div class="text-base font-semibold text-white">
                      {{ titleize(myMostUsedItem?.entity_name, '_') }}
                    </div>
                  </div>
                  <div class="px-1 py-2">
                    <div class="text-xl font-semibold text-white">
                      {{ myMostUsedItem?.transaction_count }}
                    </div>
                    <div class="text-xs">Transactions</div>
                  </div>
                </BaseCard>
              </div>
            </div>
          </template>
        </BaseCard>
      </div>
    </div>
    <div v-else class="flex h-screen items-center justify-center">
      <NoWalletConnected
        class="h-full w-full"
        text="Connect Wallet Address to view Recommendations"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  watch,
  computed,
  onUnmounted,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { RecommendationApi } from '../Recommendations/RecommendationApi';
import BaseCard from '../../Shared/BaseCard.vue';
import RecommendedBaseCard from '../../Shared/BaseCardRecommended.vue';
import Tooltip from '../../Shared/Tooltip.vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import BasePaginate from '../../Shared/BasePaginate.vue';
import { titleize, getGameIcon } from '../../composeables/filters.js';
import IconLoading from '../../icons/IconLoading.vue';
import NoWalletConnected from '../../load_templates/NoWalletConnected.vue';

const store = useStore();
const projectImagesBaseLink = store.getters.projectImagesBaseLink;
const api = new RecommendationApi();
const loading = ref(true);
const recommendationsData = ref();
const mostUsedData = ref();
async function fetchRecommendationData() {
  loading.value = true;
  const { recommendations, new_user_most_used } =
    await api.fetchRecommendation();
  recommendationsData.value = recommendations;
  mostUsedData.value = new_user_most_used;

  // recommendationsData.value = recommendations.filter((item) => item?.data);
  // mostUsedData.value = new_user_most_used.filter((item) => item?.data);
  loading.value = false;
}

onMounted(fetchRecommendationData);
const address = localStorage.getItem('my_address');

const entityData = (vertical) => {
  return computed(() => {
    return recommendationsData.value
      ?.filter((entity) => entity?.entity_vertical === vertical)
      ?.sort((a, b) => (b.data ? 1 : -1));
  });
};

onMounted(() => {
  updateItemsPerPage();
  window.addEventListener('resize', updateItemsPerPage);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateItemsPerPage);
});

// CALCULATE WIDTH
const updateItemsPerPage = () => {
  if (window.innerWidth < 770) {
    itemsPerPage.value = 1;
    L1sItemsPerPage.value = 1;
  } else if (window.innerWidth < 1030) {
    itemsPerPage.value = 2;
    L1sItemsPerPage.value = 4;
  } else {
    itemsPerPage.value = 3;
    L1sItemsPerPage.value = 6;
  }
};

// DEFI DATA
const defiData = entityData('defi');
const defiPage = ref(1);
const itemsPerPage = ref(3);
const L1sItemsPerPage = ref(6);
const defiMetrics = [
  { Price: 'price', prefix: '$', decimals: 6 },
  { 'Market Cap': 'market_cap', prefix: '$' },
  { 'Total Transaction': 'num_transactions_1d' },
  { TVL: 'tvl', prefix: '$' },
];
const defiTotalPages = computed(() => {
  return Math.ceil(defiData.value?.length / itemsPerPage.value);
});

const paginatedDefiData = computed(() => {
  const start = (defiPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return defiData.value?.slice(start, end);
});

// GAMING DATA
const gamingData = entityData('gamefi');
const gamingPage = ref(1);
const gamingMetrics = [
  { 'Total Transaction': 'total_game_num_transactions', decimals: 0 },
  { 'Total Wallets': 'total_game_unique_wallets', decimals: 0 },
  { 'Market Caps': 'market_cap', decimals: 0 },
];
const gamingTotalPages = computed(() => {
  return Math.ceil(gamingData.value?.length / itemsPerPage.value);
});
const paginatedGamingData = computed(() => {
  const start = (gamingPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return gamingData.value?.slice(start, end);
});

// NFT DATA
const nftData = entityData('nft');
const nftPage = ref(1);
const nftMetrics = [
  { 'Avg Sales (USD)': 'avg_sales_usd', prefix: '$', decimals: 2 },
  { 'Avg Sales (AVAX)': 'avrg_price', decimals: 2 },
  { 'Avg Sales': 'num_trxs', decimals: 0 },
  { 'Volume (AVAX)': 'vol', decimals: 2 },
];
const nftTotalPages = computed(() => {
  return Math.ceil(nftData.value?.length / itemsPerPage.value);
});
const paginatedNftData = computed(() => {
  const start = (nftPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return nftData.value?.slice(start, end);
});

// TOKEN DATA
const tokenData = entityData('token_project');
const tokenPage = ref(1);
const tokenMetrics = [
  { Price: 'price', prefix: '$', decimals: 2 },
  { 'Market Cap': 'market_cap', prefix: '$', decimals: 2 },
  { 'Trading Volume': 'trading_volume', prefix: '$' },
  { TVL: 'tvl', prefix: '$' },
];
const tokenTotalPages = computed(() => {
  return Math.ceil(tokenData.value?.length / itemsPerPage.value);
});
const paginatedTokenData = computed(() => {
  const start = (tokenPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return tokenData.value?.slice(start, end);
});

// L1 METRICS
const l1Metrics = [
  { 'Active Addresses': 'active_senders' },
  { 'Transaction Count': 'transaction_count' },
  { Contracts: 'contracts' },
  { 'Gas Used': 'gas_used' },
];
</script>
