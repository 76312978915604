<template>
  <div class="w-full space-y-4 md:items-center lg:space-y-2">
    <div class="grid grid-cols-1 gap-4">
      <div
        class="w-full"
        v-for="item in statsItems"
        :key="item.id"
        id="lineGraphContainer"
      >
        <StatsWidget
          :dollar-sign="item.dollarSign"
          :stat-item="item"
          :show-loading="loading"
          :tooltip-text="item.tooltip"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import StatsWidget from '../../Widgets/Stats.vue';
import { orderBy } from 'lodash';
import {
  calculateChange,
  chillFactorCalculation,
} from '../../composeables/filters.js';
import moment from 'moment';
const props = defineProps({
  loading: {
    type: Boolean,
  },
  componentHistorical: {
    type: Object,
    default: {},
  },
});

const statsItems = computed(() => {
  let graphItems = [
    {
      id: 1,
      title: 'Participation Score',
      total: 0,
      from: 0,
      growth: 0,
      chart: [],
      tooltip:
        'This shows how your Participation Score contributions have changed over time. Metrics in the Participation Score include total transactions sent, days active, and gas spent.',
    },
    {
      id: 2,
      title: 'Consistency Score',
      total: 0,
      from: 0,
      growth: 0,
      chart: [],
      tooltip:
        'This shows how your Participation Score contributions have changed over time. The metric tracked by the Consistency Score is consecutive months of activity.',
    },
    {
      id: 3,
      title: 'Diversity Score',
      total: 0,
      from: 0,
      growth: 0,
      chart: [],
      tooltip:
        'This shows how your Diversity Score contributions have changed over time. Metrics in the Diversity Score track interactions with unique tokens, NFT collections, and contracts.',
    },
  ];

  let activity_contr = [];
  let consistency_contr = [];
  let diversity_contr = [];

  if (Object.keys(props.componentHistorical).length > 0) {
    let data = orderBy(props.componentHistorical, 'cur_date', 'desc');
    data.map((item) => {
      graphItems[0].chart.push([
        moment(item.cur_date, 'YYYY-MM-DD HH:mm:ss').unix() * 1000,
        chillFactorCalculation(item['activity_contr'], item['score'], true) ||
          0,
      ]);
      graphItems[1].chart.push([
        moment(item.cur_date, 'YYYY-MM-DD HH:mm:ss').unix() * 1000,
        chillFactorCalculation(
          item['consistency_contr'],
          item['score'],
          true,
        ) || 0,
      ]);
      graphItems[2].chart.push([
        moment(item.cur_date, 'YYYY-MM-DD HH:mm:ss').unix() * 1000,
        chillFactorCalculation(item['diversity_contr'], item['score'], true) ||
          0,
      ]);
      activity_contr.push(
        chillFactorCalculation(item['activity_contr'], item['score'], true),
      );
      consistency_contr.push(
        chillFactorCalculation(item['consistency_contr'], item['score'], true),
      );
      diversity_contr.push(
        chillFactorCalculation(item['diversity_contr'], item['score'], true),
      );
    });

    graphItems[0].total = activity_contr[0];
    graphItems[1].total = consistency_contr[0];
    graphItems[2].total = diversity_contr[0];

    graphItems[0].from = activity_contr.at(-1);
    graphItems[1].from = consistency_contr.at(-1);
    graphItems[2].from = diversity_contr.at(-1);

    graphItems[0].growth = calculateChange(
      activity_contr[0],
      activity_contr[1],
    );
    graphItems[1].growth = calculateChange(
      consistency_contr[0],
      consistency_contr[1],
    );
    graphItems[2].growth = calculateChange(
      diversity_contr[0],
      diversity_contr[1],
    );
  }
  return graphItems;
});
</script>
